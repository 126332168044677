import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { createStateSyncMiddleware, withReduxStateSync } from "redux-state-sync";
import { sessionReducer } from "redux-react-session";

import settingReducer from "./reducers/SettingReducer";
import sensorReducer from "./reducers/SensorReducer";
import templateReducer from "./reducers/TemplateReducer";

export const store = configureStore({
    reducer: {
        session: sessionReducer,
        setting: settingReducer,
        sensor: sensorReducer,
        template: templateReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createStateSyncMiddleware(withReduxStateSync))
})