import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Form, Input, message } from "antd";
import axios from "axios";
import paket from '../../package.json';
import { useSelector } from "react-redux";

function UserPasswordView(){

    const user = useSelector(state => state.session.user);

    const finish = e => {
        if(e['newpassword']!== '' && e['newpassword']!==e['newpassword2']){
            message.warning('Password baru tidak sama !');
        } else {
            e['id'] = user.id;
            axios.post(paket.app_url+'/user/change', e).then(res=>{
                console.log(res.data);
                let json = res.data;
                if(json.status===1){
                    message.success(json.message);
                } else {
                    message.warning(json.message);
                }
            }).catch(er=>message.error(er.message));
        }
    }

    return(
        <Card title='Ganti Password'> 
            <Form onFinish={finish}>
                <Form.Item label='Password saat ini' name='password' rules={[{required: true}]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item label='Password baru' name='newpassword' rules={[{required: true}]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item label='Password ulang password baru' name='newpassword2' rules={[{required: true}]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="primary" icon={<FontAwesomeIcon icon={faSave} />}>Simpan</Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default UserPasswordView;