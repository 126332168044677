import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Space } from "antd";

function UpdateView(){

    const refresh = () => {
        window.location.reload();
    }

    return(
        <Space direction="vertical" style={{width: '100%'}}>
            <Alert message='Versi terbaru tersedia !' showIcon />
            <Button type="primary" onClick={refresh} icon={<FontAwesomeIcon icon={faRefresh} />} >Update</Button>
        </Space>
    );
}

export default UpdateView;