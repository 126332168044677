import { Form, InputNumber, Skeleton, Switch, message } from "antd";
import { setSetting } from "../reducers/SettingReducer";
import Card from "antd/es/card/Card";
import axios from "axios";
import paket from '../../package.json'
import { useDispatch, useSelector } from "react-redux";

function ScheduleView(){

    const setting = useSelector(state => state.setting);
    const dispatch = useDispatch();

    const ganti = (kolom, nilai) => {
        let set = {...setting}; set[kolom] = nilai; dispatch(setSetting(set))
        if(nilai===true){
            nilai = 'true';
        } else {
            nilai = 'false';
        }
        axios.post(paket.app_url+'/device/set', {'name': kolom, 'value': nilai}).then(res=>{
            //console.log(res.data);
        }).catch(er=>message.error(er.message));
    }

    const ganti2 = (kolom, nilai) => {
        let set = {...setting}; set[kolom] = nilai; dispatch(setSetting(set))
        axios.post(paket.app_url+'/device/set', {'name': kolom, 'value': nilai}).then(res=>{
            //console.log(res.data);
        }).catch(er=>message.error(er.message));
    }

    return(
        setting.ready===false ? <Skeleton active /> :
        <Card title='Otomatis'>
            <Form layout="horizontal">
                <Form.Item label='Jalankan Otomatis'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' onChange={e=> ganti('otomatis', e)} checked={setting.otomatis} />
                </Form.Item>
                <Form.Item label='Penggantian Air Tiap'>
                    {setting.otomatis===false ?
                    <InputNumber addonAfter='Menit' value={setting.penggantian_air} onChange={e=>ganti2('penggantian_air', e)} />
                    : <><b>{setting.penggantian_air}</b> Menit</> }
                </Form.Item>
                <Form.Item label='Durasi Pompa On'>
                    {setting.otomatis===false ?
                    <InputNumber addonAfter='Menit' value={setting.lama_penggantian} onChange={e=>ganti2('lama_penggantian', e)} />
                    : <><b>{setting.lama_penggantian}</b> Menit</> }
                </Form.Item>
                <Form.Item label='Kirim Ke KLHK tiap jam'>
                    <Switch checkedChildren='Ya Kirim' unCheckedChildren='Tidak di Kirim' onChange={e=> ganti('kirim', e)} checked={setting.kirim} />
                </Form.Item>
            </Form>
        </Card>
    );
}

export default ScheduleView;