import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { sessionService } from "redux-react-session";

function UserLogoutView(){

    const nav = useNavigate();
    const logout = () => {
        sessionService.deleteUser().then(()=>{
            nav('/');
        });
    }

    return(
        <Space direction="vertical" style={{width: '100%'}}>
            <Alert message='Konfirmasi logout ?' showIcon type="warning" />
            <Button onClick={logout} type="primary" icon={<FontAwesomeIcon icon={faSignOutAlt} />}>Ya Logout</Button>
        </Space>
    );
}

export default UserLogoutView;