import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    ready: false,
    otomatis: false,
    sumber_sungai: false,
    sumber_bak: false,
    pembuangan_bak: false,
    pembuangan_sungai: false,
    pompa: false,
    penggantian_air: 60,
    lama_penggantian: 5,
    kirim: false,
    versi: ''
}

export const settingReducer = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        setSetting: (state, action) => {
            state.ready = action.payload.ready;
            state.otomatis = action.payload.otomatis;
            state.sumber_sungai = action.payload.sumber_sungai;
            state.sumber_bak = action.payload.sumber_bak;
            state.pembuangan_bak = action.payload.pembuangan_bak;
            state.pembuangan_sungai = action.payload.pembuangan_sungai;
            state.pompa = action.payload.pompa;
            state.penggantian_air = action.payload.penggantian_air;
            state.lama_penggantian = action.payload.lama_penggantian;
            state.kirim = action.payload.kirim;
            state.versi = action.payload.versi;
        }
    }
});

export const { setSetting } = settingReducer.actions;
export default settingReducer.reducer;