import { Alert, Card, Col, Row, Skeleton, Space, Switch, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setSetting } from "../reducers/SettingReducer";
import axios from "axios";
import paket from '../../package.json'

function SettingView(){

    const setting = useSelector(state => state.setting);
    const dispatch = useDispatch();

    const ganti = (kolom, nilai) => {
        let set = {...setting}; set[kolom] = nilai; dispatch(setSetting(set))
        if(nilai===true){
            nilai = 'true';
        } else {
            nilai = 'false';
        }
        axios.post(paket.app_url+'/device/set', {'name': kolom, 'value': nilai}).then(res=>{
            //console.log(res.data);
        }).catch(er=>message.error(er.message));
    }

    return(
        setting.ready===false ? <Skeleton active /> :
        <Space direction="vertical" style={{width: '100%'}}>
            {setting.otomatis === true ? <Alert message='Jadwal Otomatis Jalan !' type="success" showIcon /> : null }
            <Card title='Valve Sumber Air'>
                <Row>
                    <Col span={12}>
                        {setting.otomatis === false ?
                        <Switch checked={setting.sumber_sungai} checkedChildren='Sungai Buka' unCheckedChildren="Sungai Tutup" onChange={e=>{ ganti('sumber_sungai', e)}} />
                        :
                        <Switch disabled checked={setting.sumber_sungai} checkedChildren='Sungai Buka' unCheckedChildren="Sungai Tutup" />
                        }
                    </Col>
                    <Col  span={12}>
                        {setting.otomatis === false ?
                        <Switch checked={setting.sumber_bak} checkedChildren='Bak Analisis Buka' unCheckedChildren="Bak Analisis Tutup" onChange={e=>{ ganti('sumber_bak', e)}} />
                        :
                        <Switch disabled checked={setting.sumber_bak} checkedChildren='Bak Analisis Buka' unCheckedChildren="Bak Analisis Tutup" />
                        }
                    </Col>
                </Row>
            </Card>
            <Card title='Valve Air Keluar'>
                <Row>
                    <Col span={12}>
                        {setting.otomatis === false ?
                        <Switch checked={setting.pembuangan_bak} checkedChildren='Bak Analisis Buka' unCheckedChildren="Bak Analisis Tutup" onChange={e=>{ ganti('pembuangan_bak', e)}} />
                        :
                        <Switch disabled checked={setting.pembuangan_bak} checkedChildren='Bak Analisis Buka' unCheckedChildren="Bak Analisis Tutup" />
                        }
                    </Col>
                    <Col span={12}>
                        {setting.otomatis === false ?
                        <Switch checked={setting.pembuangan_sungai} checkedChildren='Sungai Buka' unCheckedChildren="Sungai Tutup" onChange={e=>{ ganti('pembuangan_sungai', e)}} />
                        :
                        <Switch disabled checked={setting.pembuangan_sungai} checkedChildren='Sungai Buka' unCheckedChildren="Sungai Tutup"  />
                        }
                    </Col>
                </Row>
            </Card>
            <Card title='Pompa Aktif'>
                {setting.otomatis === false ?
                <Switch checked={setting.pompa} checkedChildren='Pompa On' unCheckedChildren="Pompa Off" onChange={e=>{ ganti('pompa', e)}} />
                :
                <Switch disabled checked={setting.pompa} checkedChildren='Pompa On' unCheckedChildren="Pompa Off" />
                }
            </Card>
        </Space>
    );
}

export default SettingView;