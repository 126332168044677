import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Form, Input, Row, message } from "antd";
import axios from "axios";
import paket from '../../package.json';
import { useState } from "react";
import { sessionService } from "redux-react-session";
import { useNavigate } from "react-router-dom";

function UserLoginView(){

    const nav = useNavigate();
    const [loading, setLoading] = useState(false);
    const finish = e => {
        console.log(e);
        setLoading(true);
        axios.post(paket.app_url+'/user/login', e).then(res=>{
            setLoading(false);
            let json = res.data;
            if(json.status===1){
                message.success(json.message);
                let data = {
                    'app_id' : paket.app_id,
                    'username' : json.user.username,
                    'id' : json.user.id
                }
                sessionService.saveUser(data).then(()=>{
                    nav('/');
                });
            } else {
                message.warning(json.message);
            }
        }).catch(er=>message.error(er.message));
    }

    return(
        <Row>
            <Col xs={0} sm={0} md={8} lg={9}></Col>
            <Col xs={24} sm={24} md={8} lg={6}>
                <Card title='User Login'>
                    <Form onFinish={finish}>
                        <Form.Item label='Username' name='username' rules={[{required: true}]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label='Password' name='password' rules={[{required: true}]}>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item>
                            <Button loading={loading} type="primary" htmlType="submit" icon={<FontAwesomeIcon icon={faSignInAlt} />}>Login</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col xs={0} sm={0} md={8} lg={9}></Col>
        </Row>
    );
}

export default UserLoginView;