import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    title: '',
    alamat: ''
}

export const templateReducer = createSlice({
    name: 'template',
    initialState,
    reducers: {
        setTemplate: (state, action) => {
            state.title = action.payload.title;
            state.alamat = action.payload.alamat;
        }
    }
});

export const { setTemplate } = templateReducer.actions;
export default templateReducer.reducer;