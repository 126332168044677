import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ConfigProvider } from 'antd';
import paket from '../package.json';
import { Provider } from 'react-redux';
import { store } from './store';
import { sessionService } from 'redux-react-session';
import { HashRouter } from 'react-router-dom';



const root = ReactDOM.createRoot(document.getElementById('root'));

sessionService.initSessionService(store);

root.render(

    <ConfigProvider 
        theme={{
            token: {
                colorPrimary: paket.app_primary,
                borderRadius: 5
            }
        }}
    >
        <Provider store={store}>
            <HashRouter>
                <App />
            </HashRouter>
        </Provider>
    </ConfigProvider>
    

);